// useOpenAI.js
import { useState, useEffect } from 'react';
import { Configuration, OpenAIApi } from 'openai';

const useOpenAI = () => {
  const [openai, setOpenai] = useState(null);

  useEffect(() => {
    const initializeOpenAI = async () => {
      const configuration = new Configuration({
        apiKey: "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"
      });
      const openaiInstance = new OpenAIApi(configuration);
      setOpenai(openaiInstance);
    };

    initializeOpenAI();
  }, []);

  return openai;
};

export default useOpenAI;
