const darkGreen = "rgb(38, 55, 51)";
const green = "rgb(41, 93, 78)";
import { View, Text, TouchableOpacity, TextInput, ScrollView, useWindowDimensions, Animated, Image, StyleSheet } from "react-native";
import { useEffect, useRef, useState } from "react";
import { TypingAnimation } from 'react-native-typing-animation';
import { Configuration, OpenAIApi } from "openai";

import useOpenAI from './src/useOpenai';
import { Entypo } from '@expo/vector-icons';

import { CARDS } from './src/Spe';
import { useHover } from "@react-native-aria/interactions";





const template = `
Bonjour chat GPT. 
Tu es désormais un spécialiste dans les médecines douces. 
Tu sais maintenant établir quel spécialiste de médecine douce il convient le mieux de consulter en fonction de symptômes.
Tu vas donc m'aider en me posant des questions une par une pour essayer de me trouver le spécialiste de médecine douce qui serait le plus apte à m'aider en fonction de mes symptômes.

Ton but n'est en aucun cas de faire un diagnostic, mais seulement de me poser des questions.
Dans chacun des prompts, tu ne fais apparaître QUE les questions et aucune autre information.
Tu ne dois poser q'UNE SEULE question par prompt.

Les pratiques que tu connais sont:
- ostéopathe
- acupuncteur
- réflexologue
- psychologue
- psychiatre
- naturopathe

tu ne dois JAMAIS poser les mêmes questions.

Je veux que ton output FINAL soit de cette forme: 
Je veux que ton output final soit SEULEMENT de cette forme: '!X' avec X la spécialité que tu auras trouvée.

Si tu as compris ta tâche, REPONDS SEULEMENT: VALIDE
`;






const Message = ({ el, i, mess }) => {

  const scale = useRef(new Animated.Value(0)).current;

  const animScale = () => {
    Animated.spring(scale, {
      bounciness: 14,
      toValue: 1,
      speed: 3,
      useNativeDriver: true,
    }).start()
  }


return (
  <Animated.View
    onLayout={() => {
      animScale();
    }}
    style={{ maxWidth: "80%", marginTop: 10, padding: el.type == 2 ? 0 : 10, borderRadius: 10, backgroundColor: el.type == 0 ? green : darkGreen, alignSelf: el.type == 0 ? "flex-end" : "flex-start", transform: [{ scale: scale }] }}>
    {el.type == 2 && i == mess.length - 1 &&
      <View style={{ width: 50, height: 40, borderRadius: 10, backgroundColor: darkGreen }}>
        <TypingAnimation
          dotColor={"white"}
          dotMargin={5}
          dotAmplitude={3}
          dotSpeed={0.15}
          dotRadius={4}
          dotX={12}
          dotY={8}
          style={{ position: "absolute", top: 10, left: 10 }}
        />
      </View>
    }
    <Text style={{ fontWeight: "500", color: "white" }}>{el.content}</Text>
  </Animated.View>
)
}


const SearchArea = ({ chat, setChat }) => {
  const [query, setQuery] = useState("");
  const { height } = useWindowDimensions()
  const [h, setH] = useState(height - 15 - 15 - 50);

  const [mess, setMess] = useState([]);

  const [index, setIndex] = useState(3);
  const [loading, setLoading] = useState(false);



  console.log("SearchArea started");




  const openai = useOpenAI(); // Access the openai instance from the custom hook

  useEffect(() => {
    const getCheck = async () => {
      console.log("get check function started");

      if (openai) {
        // Check if the openai instance is available before using it
        console.log("chat openai")
        console.log(chat);
        const out = await getReply(chat, openai);

        console.log(out)
         // Now 'resp' will contain the value returned from getReply
      }
    };

    getCheck();
  }, [openai], []);

  const getReply = async (input, openai) => {
    // You can pass the openai instance to other functions that need it
    console.log("get reply Started");
    console.log(input);
    let reply;

    try {
      const response = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: input,
      });
      reply = response.data.choices[0].message.content;

      console.log(reply);
      return reply;
    } catch (error) {
      console.error("Error occurred while getting the reply:", error);
      throw error;
    }
  };


  

 

  useEffect(() => {

   let resp = '';
    const gatherResponse = async () => {
      console.log("gather function started");
    setIndex((prevIndex) => prevIndex - 1); // Decrement 'index' by one
    console.log(index);
  
      setMess((old) => [...old, { type: 2, content: "" }])
      let nb_restant = [];
      if (index == 0){
        nb_restant = 
        { role: "system",
          content: "Tu dois donner ta réponse finale"
         }
        }else{
          nb_restant = { role: "system",
          content : "nombre de tentatives restantes: " + index}
        }

        chat.push(nb_restant);

   

        resp = await getReply(chat, openai);

        if(resp.startsWith("!")){
          setShowArea(false);
          setDoc(resp.slice(1));
        }else if(resp.startsWith("?")){
          setShowArea(false);
          return(
                <View style={styles.container}>
                  <Text>nous sommes désolé.Nous n'avons pas de spécialistes qui relèvent de vos symptomes sur notre plateforme.</Text>
                </View>
          );
              
          console.log("caca");
        }


    
         setMess((old) => [...old, { type: 1, content: resp }])
         setLoading(false);


        
    }

    switch (true) {
      case  resp[0] == '!':
        const spe = resp.slice(1);
        console.log(spe);
        setMess((old) => [...old, { type: 1, content: spe }]);
        setLoading(false);
        break;
    
      case resp[0] == '?':
        setMess((old) => [...old, { type: 1, content: "nous sommes désolé.Nous n'avons pas de spécialistes qui relèvent de vos symptomes sur notre plateforme." }]);
        setLoading(false);
        break;
    
      default:
        console.log(showarea);
    
        if (mess.length !== 0 && mess[mess.length - 1].type === 0) {
          gatherResponse();
        }
        break;
    }

    
}, [chat], [openai])



  useEffect(() => {

    if (mess.length != 0 && mess[mess.length - 1].type == 0) {
      setLoading(true)
      setChat((old) => [...old, {
        "role": "user",
        "content": mess[mess.length - 1].content,
      }])
    }

  }, [mess.length])

  const textOpacity = useRef(new Animated.Value(1)).current;
  const hideText = () => {
    Animated.timing(textOpacity, {
      duration: 800,
      toValue: 0,
      useNativeDriver: true,
    }).start(() => {
      setMess((old) => [...old, { type: 0, content: query }])
    })
  }

  const messScrollRef = useRef(null)
const [showarea,setShowArea] = useState(true)
const [doc, setDoc] = useState('');

console.log(doc);

const filteredCards = CARDS.filter(card => card.nom === doc);

return (
  <>
    {showarea === true ? (
      <View style={{ width: "100%", alignItems: "center", minHeight: h, paddingTop: textOpacity._value == 0 ? 0 : 0.25 * height }}>
        {textOpacity._value != 0 && (
          <Animated.Text style={{ fontSize: 45, textAlign: "center", fontWeight: "bold", opacity: textOpacity, color: darkGreen }}>
            {`Medalink AI v1`}
          </Animated.Text>
        )}
        {textOpacity._value == 0 && (
          <ScrollView
            onContentSizeChange={() => messScrollRef.current.scrollToEnd({ animated: true })}
            ref={messScrollRef}
            style={{
              minWidth: 400,
              maxWidth: 600,
              width: "50vw",
              marginTop: 50,
              paddingHorizontal: 15,
              paddingBottom: 10,
              height: textOpacity._value == 0 ? "calc(100vh - 80px - 77px - 100px)" : null // top bar (80px) | search bar (57px + 20px de margin)
            }}
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: 'flex-end',
            }}
          >
            {
              mess.map((el, i) => {
                return <Message key={i} el={el} i={i} mess={mess} />
              })
            }
          </ScrollView>
        )}
        <View style={{ flexDirection: "row", marginTop: 50 }}>
          <Entypo name="magnifying-glass" size={26} color={green} style={{ position: "absolute", top: 14, left: 14 }} /> 
          <TextInput
            editable={!loading}
            placeholder="Decrivez vos symptomes.."
            value={query}
            onChange={(e) => setQuery(e.nativeEvent.text)}
            placeholderTextColor={darkGreen}
            style={{
              marginBottom: 20,
              outline: "none",
              padding: 20,
              paddingLeft: 20 + 30,
              borderRadius: 20,
              minWidth: 400,
              maxWidth: 600,
              width: "50vw",
              // shadowColor: "#000",
              shadowColor: darkGreen,
              shadowOffset: {
                width: 0,
                height: 0,
              },
              shadowOpacity: 0.4,
              shadowRadius: 7,
              fontWeight: "500"
            }}
            onSubmitEditing={() => {
              if (textOpacity._value == 1) {
                setQuery("");
                hideText();
              } else {
                setQuery("");
                setMess((old) => [...old, { type: 0, content: query }])
              }
              // setH((old) => old+500)
            }}
          />
        </View>
      </View>
    ) : (
      <View style={styles.container}>
        {filteredCards.map((card, index) => (
          <View style={styles.card} key={index}>
            <View style={styles.cardLogo} >
              <Image style={styles.img} source={card.image} />
            </View>
            <View>
              <Text style={styles.cardTitle}>{card.nom}</Text>
            </View>
            <View>
              <Text style={styles.cardText}>{card.description}</Text>
            </View>
          </View>
        ))}
      </View>
    )}
  </>
);

}


const App = () => {



  const [chat, setChat] = useState([{role: 'system', content: template}]);


  
  

  const [showSignModal, setShowSignModal] = useState(false);
  const [isInOrUp, setIsInOrUp] = useState(false);



  return (
    <ScrollView style={{ height: "100vh" }}>
      {
        showSignModal && <AuthModal setShowModal={setShowSignModal} inOrUp={isInOrUp} />
      }
    
          <SearchArea chat={chat} setChat={setChat} />
        

    </ScrollView>
  )
}

export default App;



const styles = StyleSheet.create({
  //cards
  img2: {
    width: '70%',
    height: '90%',
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingTop: 50,

  },
  title: {
    fontSize: 20,
    display: 'flex',
    justifyContent: 'center',
    paddingVertical: 50,
  },
  prix: {
    fontSize: 15,
    display: 'flex',
    justifyContent: 'center',
    paddingVertical: 50,
  },
  cardPrix:{
    elevation: 6,
    borderRadius: 15,
    width: '80%',
    height: '25%',
    alignItems: 'center',
    shadowColor: '#000',
 
  },
  card: {
    elevation: 6,
    borderRadius: 15,
    width: '35%',
    paddingHorizontal: 10,
    paddingVertical: 30,
    marginHorizontal: '2%',
    marginVertical: '5%',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,

  },

  cardLogo: {
    width: 130,
    height: 250,
    paddingVertical: 20,

  },
  imgButton: {
    width: 40,
    height: 40,
  },
  img: {
    width: '80',
    height: '80%',
  },
  cardTitle: {
    paddingVertical: 10,
    fontSize: 17,
  },
  cardText: {
    marginHorizontal: 5,
    marginTop: 20,
    marginBottom: 20,

  },
  contTouchable: {
    flexDirection: 'row',
    alignItems: 'center',
  },

});